import React, { useState, useEffect } from 'react'
import { Layout, Row, Col } from 'antd'
import HeaderMenu   from '../components/HeaderMenu'
import HeaderMenuMobile from '../components/HeaderMenuMobile'
import BannerSection from '../components/BannerSection'
import CardInformation from '../components/CardInformation'
import ProductsCategories   from '../components/ProductsCategories'
import About from '../components/About'
import YouTube from 'react-youtube'
const { Footer } = Layout


const Home = () => {
  
  const [size, setSize] = useState()

  const opts = {
    height: '570px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    },
  }

  useEffect(()=> {
    setSize(window.innerWidth)
  }, [])



  return(<Layout>
    <BannerSection />
    <HeaderMenu />
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={styles.container}>
        <CardInformation />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={styles.container}>
        <YouTube opts={opts} videoId={'WyycpMxu344'} />
      </Col>  
    </Row>
    <Footer>Katankura - 2022</Footer>
  </Layout>)
}


const styles = {
  container: {
    padding: '0px'
  }
}


export default Home


