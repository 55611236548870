import './assets/css/App.css'
import Home from './containers/Home'


function App() {
  return (
    <Home />
  )
}

export default App
