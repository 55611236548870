import React from 'react'
import banner1 from '../assets/images/slider2.jpeg'
import banner2 from '../assets/images/slider3.jpeg'
import { Carousel } from 'antd'


const BannerSection = () => {

  return(
    <Carousel dotPosition='right'>
      <img src={banner1} style={styles.img} alt='banner' />
      <img src={banner2} style={styles.img} alt='banner2' />
    </Carousel>
  )

}

const styles = {
  img: {
    width: '100%',
    height: '100%'
  }
}

export default BannerSection
