import React from 'react'
import { Layout, Menu } from 'antd'
import logo from '../assets/images/logo.png'
import { WhatsAppOutlined } from '@ant-design/icons'

const { Header } = Layout

const HeaderMenu = () => {
  
    return(<Header style={styles.header}>
      <div style={styles.logoContainer}>
          <img style={styles.logo} src={logo} alt='logo' />
        </div>
    </Header>)
  
}


const styles = {
    header: {
    },
    logo: {
      width: '145px'
    },
    logoContainer: {
      float: 'left',
      width: '120px',
      height: '31px',
    },
    menu: {
      paddingLeft: '50%'
    },
    icon: {
      fontSize: '20px'
    }
}


export default HeaderMenu
