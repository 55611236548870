import React from 'react'
import { Card, Typography } from 'antd'
const { Paragraph } = Typography


const About = () => {

  return(<>
    <Card title='Sobre Nuestra Calidad' style={styles.container}>
      <Paragraph> 
        ISO9001: 2008, empresa aprobada por TUV. Todos los productos PKNERGY pasan por una inspección 
        completa de cinco pasos desde el material entrante hasta los productos terminados.
     </Paragraph>
    </Card>
    <Card title='Sobre nuestros productos' style={styles.container}>
      <Paragraph>
        Nuestras baterías de litio incluyen baterías Lipo curvas y circulares, baterías Lipo ultrafinas, 
        baterías Lipo de alta y baja temperatura, baterías Lipo de alta velocidad, así como baterías Lipo 
        cilíndricas para cigarrillos electrónicos. Y nuestras baterías cilíndricas de iones de litio abarcan 
        todos los modelos, como 10440/14500/16340/17500/18650/26650 con diferentes capacidades.
      </Paragraph>
    </Card>
  </>)
}

const styles = {
  container: {
    marginBottom: '20px'
  }
}


export default About
