import React from 'react'
import { Typography, Col, Row,
          Card } from 'antd'

import cat1 from '../assets/images/category_products/1.jpg'
import cat2 from '../assets/images/category_products/2.jpg'
import cat3 from '../assets/images/category_products/3.jpg'
import cat4 from '../assets/images/category_products/4.jpg'

const { Title } = Typography

const ProductsCategories = () => {
  

  return(<>
    <Title level={2}>Tipos de productos</Title>
    <Row justify="space-around">
      <Col col={6}>
        <Card title='Industrial Lithium Battery Pack' hoverable style={styles.card}>
          <img src={cat1} alt='cat1' style={styles.img} /> 
        </Card>
      </Col>
      <Col col={6}>
        <Card title='Energy Storage/Power Battery Pack' hoverable style={styles.card}>
          <img src={cat2} alt='cat2' style={styles.img} /> 
        </Card>
      </Col>
      <Col col={6}>
        <Card title='Customize Battery Pack' hoverable style={styles.card}>
          <img src={cat3} alt='cat3' style={styles.img} /> 
        </Card>
      </Col>
      <Col col={6}>
        <Card title='Charger for Lithium Batteries' hoverable style={styles.card}>
          <img src={cat4} alt='cat4' style={styles.img} /> 
        </Card>
      </Col>
    </Row>

  </>)

}

const styles = {
  img: {
    width: '150px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    margin: '10px',
    width: '100%'
  }
}

export default ProductsCategories
