import React, { useState, useRef } from 'react'
import { Card, Space, Row, 
        Col, Button, Typography,
        Form, Input, notification } from 'antd'
import Flag from 'react-world-flags'
import emailjs from '@emailjs/browser'


const { Paragraph } = Typography

const CardInformation = () => {
  
  const [flag, setFlag] = useState('es')

  const [form] = Form.useForm()


  return(<Card hoverable={true} style={styles.card} title={<Row>
      <Col span={4} align="center">
          <Button type='link' onClick={()=> setFlag('es')}>
            <Flag code='es' style={styles.flag} />
          </Button>
      </Col>
      <Col span={4} align="center"> 
          <Button type='link' onClick={()=> setFlag('de')}>
            <Flag code='de' style={styles.flag} />
          </Button>
      </Col>
      <Col span={4} align="center"> 
          <Button type='link' onClick={()=> setFlag('us')}>
            <Flag code='us' style={styles.flag} />
          </Button>
      </Col>
      <Col span={4} align="center"> 
          <Button type='link' onClick={()=>setFlag('gf')}> 
            <Flag code='gf' style={styles.flag} />
          </Button>
      </Col>
      <Col span={4} align="center"> 
          <Button type='link' onClick={()=> setFlag('pt')}> 
            <Flag code='pt' style={styles.flag} />
          </Button>
      </Col>
      <Col span={4} align="center"> 
          <Button type='link' onClick={()=> setFlag('sau')}> 
            <Flag code='sau' style={styles.flag} />
          </Button>
      </Col>
    </Row>}>
      <Row>
        <Form 
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={(values)=> {
              emailjs.send("service_w9tdeu5","template_gubw61u",{
              name: values.name,
              message: values.message,
              reply_to: "ventas@pknergy.cl",
              email: values.email,
              phone: values.phone
              }, "user_QaPzTaGSNmHfQ43qR5KdK").then(()=> {
                notification.success({message:'MENSAJE ENVIADO!'})
                form.resetFields()
              })
             
          }}
        >
        <Col span={24} align='center'>
          {flag == 'es' && <>
            <Paragraph>
              Para Importaciones hacia America Latina o Centro America por favor contactarse con Sra. Sonja Ungar, por correo electrónico s.ungar@katankura.com o por whatsapp +56989019400
            </Paragraph>
            <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Ingresa tu nombre!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Ingresa tu email!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='Mensaje' rules={[{ required: true, message: 'Ingresa tu mensaje!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>Enviar</Button>
            </>
          } 
          {flag == 'de' && <>
            <Paragraph>
              Für Importe von frischen Trüffeln oder Trüffelprodukten nach Deutschland, Österreich oder Schweiz, kontaktieren Sie bitte Frau Mag. Sonja Ungar unter der email s.ungar@katankura.com, oder unter der whatsapp nummer +56989019400
            </Paragraph>
            <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Bitte geben Sie Ihren Namen ein!!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Bitte geben Sie ihre E-Mail-Adresse ein!!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='Botschaft' rules={[{ required: true, message: 'Bitte gib deine Nachricht ein!!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>Senden</Button> 
            </>
          }
          {flag == 'us' && <>
            <Paragraph>
              Please contact Mrs. Sonja Ungar for truffle exports:
	s.ungar@katankura.com or by whatsapp +56989019400
            </Paragraph>
            <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please enter your name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Please enter your email!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='Message' rules={[{ required: true, message: 'Please enter your message!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>Send</Button>
          </>
          }
          {flag == 'gf' && <>
            <Paragraph>
              Pour les importations en France, veuillez contacter Mme Sonja Ungar, par email s.ungar@katankura.com ou par whatsapp +56989019400
            </Paragraph>
            <Form.Item name='name' label='Nom' rules={[{ required: true, message: 'Sil vous plaît entrez votre nom!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Sil vous plaît entrez votre email!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='Message' rules={[{ required: true, message: 'Veuillez saisir votre message!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>Envoyer</Button>
            </>
          }
          {flag == 'pt' && <>
            <Paragraph>
              Para Importação para o Brasil entre em contato com a Sra. Sonja Ungar, pelo email s.ungar@katankura.com ou pelo whatsapp +56989019400
            </Paragraph>
            <Form.Item name='name' label='Nome' rules={[{ required: true, message: 'Por favor insira seu nomre!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email' rules={[{ required: true, message: 'Por favor introduza o seu e-mail!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='Mensagem' rules={[{ required: true, message: 'Por favor insira a sua mensagem!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>Mandar</Button>
            </>
          }
{flag == 'sau' && <>
            <Paragraph>
تواصلوا معنا رجاءًا:
البريد الإلكتروني: s.ungar@katankura.com
الهاتف: +56989019400
الإنستاجرام: katankura_com

            </Paragraph>
            <Form.Item name='name' label='اسم' rules={[{ required: true, message: 'Por favor insira seu nomre!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='email' label='بريد' rules={[{ required: true, message: 'Por favor introduza o seu e-mail!' }]} >
              <Input />
            </Form.Item>
            <Form.Item name='message' label='رسالة' rules={[{ required: true, message: 'Por favor insira a sua mensagem!' }]}>
              <Input.TextArea />
            </Form.Item>
            <Button type='primary' htmlType='submit'>إرسال</Button>
            </>
          }
        </Col>
        </Form>
      </Row>
    </Card>)


}


const styles = {
  flag: {
    width:'40px'
  },
  card: {
    width:'100%',
    backgroundColor: '#f0f0f0',
    height: '100%'
  }
}


export default CardInformation
