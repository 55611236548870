import React from 'react'
import { Row, Col, Layout } from 'antd'
import logo from '../assets/images/logo.png'
import { WhatsAppOutlined } from '@ant-design/icons'

const { Header } = Layout


const HeaderMenuMobile = () => {

  return(<>
          <Header style={styles.header} >
            <div style={styles.container}>
              <img style={styles.logo} src={logo} />
            </div>
          </Header>
          <Header style={styles.header} >
            <Row justify='space-around'>
              <Col>
                <a href='mailto:ventas@pknergy.cl' rel='nofollow' style={styles.txtpk}>
                  ventas@pknergy.cl
                </a>
              </Col>
              <Col>
                <a href='https://api.whatsapp.com/send?phone=56965073589' target='_blank' style={styles.txtpk} rel='noreferrer'>
                  <WhatsAppOutlined style={styles.icon} /> +56 9 6507 3589
                </a>
              </Col>
            </Row>
          </Header>
          <Header style={styles.header}>
            <Row justify='center'>
              <Col>
                <a style={styles.txtpk} href="https://pkcellchile.cl/" target="_blank" rel='noreferrer' >
                  Pk Cell Chile
                </a>
              </Col>
            </Row>
          </Header>
    </>)

}

const styles = {
    header: {
      backgroundColor: 'white'
    },
    txtpk: {
      color: '#237804',
      fontSize: '15px'
    },
    logo: {
      width: '145px'
    },
    logoContainer: {
      float: 'left',
      width: '120px',
      height: '31px',
    },
    menu: {
      paddingLeft: '70%'
    },
    icon: {
      fontSize: '20px',
      color:'#237804'
    }
}


export default HeaderMenuMobile
